@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
:root {
  --headerFont: "Chillax-Medium";
  --bodyFont: "Chillax-Medium";
  --appNameFont: "Chillax-Medium";
  --white: #fff;
  --black: #000;
  --grey1Color: #ebebeb;
  --grey2Color: #c6c6c6;
  --grey3Color: #787878;
  --grey4Color: #525252;
  --grey5Color: #3a3a3a;
  --red: #ee0902;
  --redDark: #811515;
  --redDisabled: #fc7979;
  --success: #219653;
  --successDark: #176b3b;
  --successDisabled: #28c76d;
}
.light {
  --textColor: #323232;
  --themeColor: #f42f54;
  --themeColorDark: #e51d43;
  --secondaryColor: #3f355f;
  --secondaryColorDark: #28213d;
  --themeColorSelected: #1563bb27;
  --labelColor: #737373;
  --hoverBackground: #f0f0f9;
  --bgColor: #fff;
  --bgColorDark: #f0f0f9;
  --selectBg: #3f355f;
  --selectBgDark: #28213d;
  --selectColor: #fff;
}

.dark {
  --textColor: #fff;
  --themeColor: #6639e4;
  --themeColorDark: #4b26af;
  --secondaryColor: #282454;
  --secondaryColorDark: #1e1b3f;
  --themeColorSelected: #282454d3;
  --labelColor: #737373;
  --hoverBackground: #f0f0f9;
  --bgColor: #131128;
  --bgColorDark: #271e41;
  --selectBg: #fff;
  --selectBgDark: #f0f0f9;
  --selectColor: #323232;
}
.App {
  background: url("./assets/bg.png");

  font-family: "Chillax-Medium" !important;
  color: var(--textColor);
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.p-dialog-mask {
  backdrop-filter: blur(1.5px) !important;
}
// .Neon {
//   position: absolute;
//   width: 1557.53px;
//   height: 762.53px;
//   left: -502.67px;
//   top: 731.69px;

//   /* Cyan */

//   background: #19D2FF;
//   opacity: 0.2;
//   filter: blur(280.278px);
//   transform: matrix(0.87, -0.5, -0.5, -0.87, 0, 0);
// }

// .Pink {
//   position: absolute;
//   width: 1557.53px;
//   height: 762.53px;
//   left: 998.33px;
//   top: 1772.69px;

//   /* Pink */

//   background: #FF39F0;
//   opacity: 0.18;
//   filter: blur(280.278px);
//   transform: matrix(0.87, -0.5, -0.5, -0.87, 0, 0);
// }
body {
  margin: 0 !important;
  background-color: #131128;
}
.Col .p-panel-content {
  border: none !important;
}

.p-menubar-button {
  display: none !important;
}
