.ReactModal__Overlay {
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.5) !important;
}

body {
  font-family: "Ranade-Medium" !important;
  margin: 0;
  padding: 0;
}

button {
  font-family: "Ranade-Medium" !important;
}

:is(h1, h3, h4, h5, h6) {
  font-family: "Chillax-Medium" !important;
}

:is(h2) {
  font-family: "Poppins" !important ;
}
.pending-cube {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotatez(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotatez(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotatez(360deg);
  }
}
