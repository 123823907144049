.header-container {
  .menu-bar {
    background-color: transparent !important;
    border: none !important;
    padding: 1rem 4rem;
    .action-button {
      background: linear-gradient(
          318.86deg,
          #563bd3 0%,
          rgba(123, 35, 195, 0) 53.86%
        ),
        radial-gradient(
          83.68% 83.68% at 0% 0%,
          #6fe38f 0%,
          rgba(133, 66, 244, 0.2) 95.83%
        );
      border: 0px solid hsla(0, 0%, 100%, 0.4);
      box-sizing: border-box;
      border-radius: 25px;
      font-weight: 100;
      font-family: "Chillax-Medium" !important;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      margin-right: 2rem;
      text-align: center;
      text-transform: capitalize;
      color: #fff;
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      min-width: 7rem;
      height: 2.5rem;
      padding: 0.4rem 1.3rem;
      margin-top: 0.7rem;
      position: absolute;
      right: 11.5rem;
      top: 1rem;
      cursor: pointer;
      &:hover {
        background: linear-gradient(
            342deg,
            #6fe38f,
            rgba(133, 66, 244, 0.2) 53.86%
          ),
          radial-gradient(
            8368% 83.68% at 0 0,
            #563bd3 0,
            rgba(123, 35, 195, 0) 150%
          );
      }
    }
  }
}
@media all and (max-width: 770px) {
  .header-container {
    .menu-bar {
      padding: 0.2rem;
      .action-button {
        background: linear-gradient(
            318.86deg,
            #563bd3 0%,
            rgba(123, 35, 195, 0) 53.86%
          ),
          radial-gradient(
            83.68% 83.68% at 0% 0%,
            #6fe38f 0%,
            rgba(133, 66, 244, 0.2) 95.83%
          );
        border: 0px solid hsla(0, 0%, 100%, 0.4);
        box-sizing: border-box;
        border-radius: 25px;
        font-weight: 100;
        font-family: "Chillax-Medium" !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        margin-right: 2rem;
        text-align: center;
        text-transform: capitalize;
        color: #fff;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        min-width: 7rem;
        height: 2.5rem;
        padding: 0.4rem 1.3rem;
        margin-top: 0.7rem;
        position: absolute;
        right: 11.5rem;
        top: 1rem;
        cursor: pointer;
        &:hover {
          background: linear-gradient(
              342deg,
              #6fe38f,
              rgba(133, 66, 244, 0.2) 53.86%
            ),
            radial-gradient(
              8368% 83.68% at 0 0,
              #563bd3 0,
              rgba(123, 35, 195, 0) 150%
            );
        }
      }
    }
  }
}
@media all and (max-width: 450px) {
  .header-container {
    .menu-bar {
      padding: 0.2rem;
      .action-button {
        background: linear-gradient(
            318.86deg,
            #563bd3 0%,
            rgba(123, 35, 195, 0) 53.86%
          ),
          radial-gradient(
            83.68% 83.68% at 0% 0%,
            #6fe38f 0%,
            rgba(133, 66, 244, 0.2) 95.83%
          );
        border: 0px solid hsla(0, 0%, 100%, 0.4);
        box-sizing: border-box;
        border-radius: 25px;
        font-weight: 100;
        font-family: "Chillax-Medium" !important;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        margin-right: 2rem;
        text-align: center;
        text-transform: capitalize;
        color: #fff;
        -webkit-backdrop-filter: blur(4px);
        backdrop-filter: blur(4px);
        min-width: 7rem;
        height: 2.5rem;
        padding: 0.4rem 1.3rem;
        margin-top: 0.7rem;
        position: absolute;
        right: 11.5rem;
        top: 1rem;
        cursor: pointer;
        &:hover {
          background: linear-gradient(
              342deg,
              #6fe38f,
              rgba(133, 66, 244, 0.2) 53.86%
            ),
            radial-gradient(
              8368% 83.68% at 0 0,
              #563bd3 0,
              rgba(123, 35, 195, 0) 150%
            );
        }
      }
    }
  }
}
